<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.analyse-skeleton.title')" />
    </ion-header>
    <ion-content :fullscreen="true" scroll-y="false">
        <div id="videoContainer" :class="(graphVisible) ? 'hidden-part' : ''">
            <div>
                <input ref="leftVideoFileChooser" @change="loadVideoFile('left', $event.target)" type="file" accept="video/*"/>
                <ion-button 
                    expand="block"
                    :fill="(leftVideoFileStore != null) ? 'solid' : 'outline'"
                    @click="leftVideoFileChooser.click()">
                    <ion-icon slot="start" :icon="film"></ion-icon>
                    <span v-if="leftVideoFileName">{{ leftVideoFileName }}</span>
                    <span v-else>{{ i18n.$t('tools.analyse-skeleton.left-video-file') }}</span>
                </ion-button>
                <div class="video-container">
                    <ion-button class="play-button" fill="solid" shape="round" mode="md" color="success" slot="start" @click="playPause('left')">
                        <ion-icon slot="icon-only" :icon="(leftPlayInterval != null) ? pause : play"></ion-icon>
                    </ion-button>
                    <ion-buttons class="zoom-buttons">
                        <ion-button fill="solid" shape="round" mode="md" color="tertiary" slot="start" @click="leftZoomLevel = 1">
                            <ion-icon slot="icon-only" :icon="expand"></ion-icon>
                        </ion-button>
                        <ion-button fill="solid" shape="round" mode="md" color="tertiary" slot="start" :disabled="leftZoomLevel <= ZOOMSTEP" @click="leftZoomLevel -= ZOOMSTEP">
                            <ion-icon slot="icon-only" :icon="removeCircle"></ion-icon>
                        </ion-button>
                        <ion-button fill="solid" shape="round" mode="md" color="tertiary" slot="start" @click="leftZoomLevel += ZOOMSTEP">
                            <ion-icon slot="icon-only" :icon="addCircle"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                    <PointCanvas 
                        ref="leftPointCanvasInstance"
                        :drawingSource="leftVideoPlayerInstance"
                        :sourceSize="leftVideoDimensions"
                        :currentFrameObject="leftVisibleFrame"
                        :zoomLevel="leftZoomLevel"
                        :enableInteraction="false"
                        alwaysShowSkeleton>
                    </PointCanvas>
                    <video @seeked="leftPointCanvasInstance.updateCanvas()" @loadedmetadata="loadedVideoMetadata('left')" ref="leftVideoPlayerInstance" muted></video>
                </div>
                <ion-range :disabled="leftVideoFrameCount <= 0" min="0" :max="leftVideoFrameCount - 1" step="1" snaps="true" ticks="false" pin="true" color="primary" v-model="leftVideoScrubPosition">
                    <ion-button :disabled="leftVideoFrameCount <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="start" @click="leftVideoScrubPosition -= 1">
                        <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                    </ion-button>
                    <ion-button :disabled="leftVideoFrameCount <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="end" @click="leftVideoScrubPosition += 1">
                        <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                    </ion-button>
                </ion-range>
            </div>
            <div>
                <input ref="rightVideoFileChooser" @change="loadVideoFile('right', $event.target)" type="file" accept="video/*"/>
                <ion-button 
                    expand="block"
                    :fill="(rightVideoFileStore != null) ? 'solid' : 'outline'"
                    @click="rightVideoFileChooser.click()">
                    <ion-icon slot="start" :icon="film"></ion-icon>
                    <span v-if="rightVideoFileName">{{ rightVideoFileName }}</span>
                    <span v-else>{{ i18n.$t('tools.analyse-skeleton.right-video-file') }}</span>
                </ion-button>
                <div class="video-container">
                    <ion-button class="play-button" fill="solid" shape="round" mode="md" color="success" slot="start" @click="playPause('right')">
                        <ion-icon slot="icon-only" :icon="(rightPlayInterval != null) ? pause : play"></ion-icon>
                    </ion-button>
                    <ion-buttons class="zoom-buttons">
                        <ion-button fill="solid" shape="round" mode="md" color="tertiary" slot="start" @click="rightZoomLevel = 1">
                            <ion-icon slot="icon-only" :icon="expand"></ion-icon>
                        </ion-button>
                        <ion-button fill="solid" shape="round" mode="md" color="tertiary" slot="start" :disabled="rightZoomLevel <= ZOOMSTEP" @click="rightZoomLevel -= ZOOMSTEP">
                            <ion-icon slot="icon-only" :icon="removeCircle"></ion-icon>
                        </ion-button>
                        <ion-button fill="solid" shape="round" mode="md" color="tertiary" slot="start" @click="rightZoomLevel += ZOOMSTEP">
                            <ion-icon slot="icon-only" :icon="addCircle"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                    <PointCanvas 
                        ref="rightPointCanvasInstance"
                        :drawingSource="rightVideoPlayerInstance"
                        :sourceSize="rightVideoDimensions"
                        :currentFrameObject="rightVisibleFrame"
                        :zoomLevel="rightZoomLevel"
                        :enableInteraction="false"
                        alwaysShowSkeleton>
                    </PointCanvas>
                    <video @seeked="rightPointCanvasInstance.updateCanvas()" @loadedmetadata="loadedVideoMetadata('right')" ref="rightVideoPlayerInstance" muted></video>
                </div>
                <ion-range :disabled="rightVideoFrameCount <= 0" min="0" :max="rightVideoFrameCount - 1" step="1" snaps="true" ticks="false" pin="true" color="primary" v-model="rightVideoScrubPosition">
                    <ion-button :disabled="rightVideoFrameCount <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="start" @click="rightVideoScrubPosition -= 1">
                        <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                    </ion-button>
                    <ion-button :disabled="rightVideoFrameCount <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="end" @click="rightVideoScrubPosition += 1">
                        <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                    </ion-button>
                </ion-range>
            </div>
        </div>
        <div id="chartContainer" :class="(!graphVisible) ? 'hidden-part' : ''">
            <div>
                <input ref="leftLabelFileChooser" @change="loadLabelFile('left', $event.target)" type="file" accept=".csv"/>
                <ion-button 
                    expand="block"
                    :fill="(leftLabelFileStore != null) ? 'solid' : 'outline'"
                    @click="leftLabelFileChooser.click()">
                    <ion-icon slot="start" :icon="analytics"></ion-icon>
                    <span v-if="leftLabelFileName">{{ leftLabelFileName }}</span>
                    <span v-else>{{ i18n.$t('tools.analyse-skeleton.left-label-file') }}</span>
                </ion-button>
                <div class="canvas-container">
                    <ion-fab vertical="top" horizontal="end" color="tertiary" class="graph-buttons">
                        <ion-fab-button :disabled="leftDatasets == null || leftDatasets.length <= 0" color="tertiary">
                            <ion-icon :icon="ellipsisHorizontal"></ion-icon>
                        </ion-fab-button>
                        <ion-fab-list side="start">
                            <ion-fab-button v-if="leftDatasets != null && leftDatasets.length > 0" mode="md" color="tertiary" @click="exportData('left')">
                                <ion-icon :icon="save"></ion-icon>
                            </ion-fab-button>
                            <ion-fab-button v-if="leftDatasets != null && leftDatasets.length > 0" mode="md" color="tertiary" @click="toggleTooltip('left')">
                                <div class="disable-line" v-if="!leftTooltipEnabled"></div>
                                <ion-icon :icon="information"></ion-icon>
                            </ion-fab-button>
                            <ion-fab-button v-if="leftDatasets != null && leftDatasets.length > 0" mode="md" color="tertiary" @click="toggleLegend('left')">
                                <div class="disable-line" v-if="!leftLegendEnabled"></div>
                                <ion-icon :icon="list"></ion-icon>
                            </ion-fab-button>
                            <ion-fab-button v-if="leftDatasets != null && leftDatasets.length > 0" mode="md" color="tertiary" @click="resetZoom('left')">
                                <ion-icon :icon="expand"></ion-icon>
                            </ion-fab-button>
                        </ion-fab-list>
                    </ion-fab>
                    <canvas :class="(leftDatasetSize > 0) ? '' : 'hidden'" ref="leftChart"></canvas>
                </div>
                <ion-range min="0" :disabled="leftDatasetSize <= 0" :max="leftDatasetSize - 1" step="1" snaps="true" ticks="false" pin="true" color="primary" v-model="leftLabelScrubPosition">
                    <ion-button :disabled="leftDatasetSize <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="start" @click="leftLabelScrubPosition -= 1">
                        <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                    </ion-button>
                    <ion-button :disabled="leftDatasetSize <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="end" @click="leftLabelScrubPosition += 1">
                        <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                    </ion-button>
                </ion-range>
            </div>
            <div>
                <input ref="rightLabelFileChooser" @change="loadLabelFile('right', $event.target)" type="file" accept=".csv"/>
                <ion-button 
                    expand="block"
                    :fill="(rightLabelFileStore != null) ? 'solid' : 'outline'"
                    @click="rightLabelFileChooser.click()">
                    <ion-icon slot="start" :icon="analytics"></ion-icon>
                    <span v-if="rightLabelFileName">{{ rightLabelFileName }}</span>
                    <span v-else>{{ i18n.$t('tools.analyse-skeleton.right-label-file') }}</span>
                </ion-button>
                <div class="canvas-container">
                    <ion-fab vertical="top" horizontal="end" color="tertiary" class="graph-buttons">
                        <ion-fab-button :disabled="rightDatasets == null || rightDatasets.length <= 0" color="tertiary">
                            <ion-icon :icon="ellipsisHorizontal"></ion-icon>
                        </ion-fab-button>
                        <ion-fab-list side="start">
                            <ion-fab-button v-if="rightDatasets != null && rightDatasets.length > 0" mode="md" color="tertiary" @click="exportData('right')">
                                <ion-icon :icon="save"></ion-icon>
                            </ion-fab-button>
                            <ion-fab-button v-if="rightDatasets != null && rightDatasets.length > 0" mode="md" color="tertiary" @click="toggleTooltip('right')">
                                <div class="disable-line" v-if="!rightTooltipEnabled"></div>
                                <ion-icon :icon="information"></ion-icon>
                            </ion-fab-button>
                            <ion-fab-button v-if="rightDatasets != null && rightDatasets.length > 0" mode="md" color="tertiary" @click="toggleLegend('right')">
                                <div class="disable-line" v-if="!rightLegendEnabled"></div>
                                <ion-icon :icon="list"></ion-icon>
                            </ion-fab-button>
                            <ion-fab-button v-if="rightDatasets != null && rightDatasets.length > 0" mode="md" color="tertiary" @click="resetZoom('right')">
                                <ion-icon :icon="expand"></ion-icon>
                            </ion-fab-button>
                        </ion-fab-list>
                    </ion-fab>
                    <canvas :class="(rightDatasetSize > 0) ? '' : 'hidden'" ref="rightChart"></canvas>
                </div>
                <ion-range min="0" :disabled="rightDatasetSize <= 0" :max="rightDatasetSize - 1" step="1" snaps="true" ticks="false" pin="true" color="primary" v-model="rightLabelScrubPosition">
                    <ion-button :disabled="rightDatasetSize <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="start" @click="rightLabelScrubPosition -= 1">
                        <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                    </ion-button>
                    <ion-button :disabled="rightDatasetSize <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="end" @click="rightLabelScrubPosition += 1">
                        <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                    </ion-button>
                </ion-range>
            </div>
        </div>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-item id="interaction-container" lines="none">
                <ion-label position="stacked">{{ i18n.$t('tools.analyse-skeleton.both-range') }}</ion-label>
                <ion-range id="both-range" min="0" :disabled="bothDatasetSize <= 0" :max="bothDatasetSize" step="1" snaps="true" ticks="false" pin="true" color="primary" v-model="bothScrubPosition">
                    <ion-button :disabled="bothDatasetSize <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="start" @click="bothScrubPosition -= 1">
                        <ion-icon slot="icon-only" :icon="playSkipBack"></ion-icon>
                    </ion-button>
                    <ion-button :disabled="bothDatasetSize <= 0" fill="solid" shape="round" mode="md" class="skip-button" color="primary" slot="end" @click="bothScrubPosition += 1">
                        <ion-icon slot="icon-only" :icon="playSkipForward"></ion-icon>
                    </ion-button>
                </ion-range>
            </ion-item>
            <div id="settings-buttons">
                <ion-button fill="solid" color="tertiary" @click="chooseLabels">
                    <ion-icon slot="start" :icon="barChart"></ion-icon>
                    {{ i18n.$t('tools.analyse-skeleton.comparison') }}
                </ion-button>
                <div id="visibility-toggle">
                    <ion-icon slot="start" :icon="film" color="tertiary"></ion-icon>
                    <ion-toggle @ionChange="graphVisible = $event.target.checked" color="tertiary" :checked="graphVisible"></ion-toggle>
                    <ion-icon slot="start" :icon="analytics" color="tertiary"></ion-icon>
                </div>
            </div>
        </ion-toolbar>
    </ion-footer>
    <a ref="downloadElement" class="invisible-input" target="_blank"></a>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonRange, IonButton, IonIcon, IonToggle, IonFooter, IonToolbar, IonItem, IonLabel, IonButtons, IonFab, IonFabList, IonFabButton } from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';
import PointCanvas from '@/components/PointCanvas.vue';
import { openPointComparisonSelectModal, default as modalComponent } from '@/components/PointComparisonSelectModal.vue';

import { 
  analytics,
  film,
  playSkipBack,
  playSkipForward,
  barChart,
  addCircle,
  removeCircle,
  expand,
  play,
  pause,
  save,
  information,
  ellipsisHorizontal,
  list
} from 'ionicons/icons';

import Papa from 'papaparse'

import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

Chart.register(...registerables, annotationPlugin, zoomPlugin);

import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { useI18n } from "@/utils/i18n";
import { localError } from '@/utils/error';
import { getVideoMetadata, getFrameInfoFromMetadata } from '@/utils/media';

import { createRGBColorString, createHexColorString } from '@/utils/colors';
import { minMaxDecimation } from '@/utils/algorithms';

import _ from 'lodash';

export default  {
  name: 'AnalyseSkeleton',
  components: { IonHeader, IonContent, IonPage, MainToolbar, IonRange, IonButton, IonIcon, IonToggle, IonFooter, IonToolbar, IonItem, IonLabel, IonButtons, IonFab, IonFabList, IonFabButton, PointCanvas },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const graphVisible = ref(true);

    const downloadElement = ref(null);

    const leftVideoFileChooser = ref(null);
    const leftVideoFileName = ref(null);
    const leftVideoFileStore = ref(null);
    const leftVideoPlayerInstance = ref(null);
    const leftVideoMetadata = ref(null);
    const leftVideoDimensions = ref({width: 0, height: 0});
    const leftPlayInterval = ref(null);

    const rightVideoFileStore = ref(null);
    const rightVideoFileName = ref(null);
    const rightVideoFileChooser = ref(null);
    const rightVideoPlayerInstance = ref(null);
    const rightVideoMetadata = ref(null);
    const rightVideoDimensions = ref({width: 0, height: 0});
    const rightPlayInterval = ref(null);

    const leftLabelFileStore = ref(null);
    const leftLabelFileName = ref(null);
    const leftDatasetSize = ref(0);
    const leftLabelFileChooser = ref(null);
    const leftDatasets = ref(null);
    
    const rightLabelFileStore = ref(null);
    const rightLabelFileName = ref(null);
    const rightDatasetSize = ref(0);
    const rightLabelFileChooser = ref(null);
    const rightDatasets = ref(null);

    const leftPointCanvasInstance = ref(null);
    const rightPointCanvasInstance = ref(null);

    const leftTooltipEnabled = ref(false);
    const rightTooltipEnabled = ref(false);

    const leftLegendEnabled = ref(false);
    const rightLegendEnabled = ref(false);

    //With this we can tell that we are waiting for a specific frame
    const requestedLeftFrameIndex = ref(null);
    const requestedRightFrameIndex = ref(null);

    const leftChart = ref(null);
    var leftChartJS = null;

    const rightChart = ref(null);
    var rightChartJS = null;

    const ZOOM_DEBOUNCE = 1000;
    var leftZoomTimeout;
    var rightZoomTimeout;

    const RESIZE_DEBOUNCE = 1000;

    const leftVideoFrameCount = computed(() => {
        if (leftVideoMetadata.value != null && leftVideoMetadata.value.FrameCount != null){
            return leftVideoMetadata.value.FrameCount;
        } else {
            return 0;
        }
    });

    const rightVideoFrameCount = computed(() => {
        if (rightVideoMetadata.value != null && rightVideoMetadata.value.FrameCount != null){
            return rightVideoMetadata.value.FrameCount;
        } else {
            return 0;
        }
    });

    const setLeftScrubPositions = function(newValue){
        if (newValue < 0 || leftDatasetSize.value <= 0) { //Dataset could be empty, then don't try to bound it to its size
            leftVideoScrubPositionRef.value = 0;
            leftLabelScrubPositionRef.value = 0;
        } else {
            if (newValue >= leftDatasetSize.value) {
                leftLabelScrubPositionRef.value = leftDatasetSize.value - 1;
            } else {
                leftLabelScrubPositionRef.value = newValue;
            }

            if (leftVideoMetadata.value == null || leftVideoMetadata.value.FrameCount == null){
                leftVideoScrubPositionRef.value = 0;
            } else if (newValue >= leftVideoMetadata.value.FrameCount) {
                leftVideoScrubPositionRef.value = leftVideoMetadata.value.FrameCount - 1;
            } else {
                leftVideoScrubPositionRef.value = newValue;
            }
        }

        if (leftVideoPlayerInstance.value != null){
            let frameRate = 25;
            if (leftVideoMetadata.value != null && leftVideoMetadata.value.FrameRate != null) {
                frameRate = leftVideoMetadata.value.FrameRate;
            }
            leftVideoPlayerInstance.value.currentTime = leftVideoScrubPositionRef.value * (1.0 / frameRate); //FIXME Seeking can take very long depending on the size of the video and the codec. Try to optimize (in both videos)!
        }
    };

    const setRightScrubPositions = function(newValue){
        if (newValue < 0 || rightDatasetSize.value <= 0) { //Dataset could be empty, then don't try to bound it to its size
            rightVideoScrubPositionRef.value = 0;
            rightLabelScrubPositionRef.value = 0;
        } else {
            if (newValue >= rightDatasetSize.value) {
                rightLabelScrubPositionRef.value = rightDatasetSize.value - 1;
            } else {
                rightLabelScrubPositionRef.value = newValue;
            }

            if (rightVideoMetadata.value == null || rightVideoMetadata.value.FrameCount == null){
                rightVideoScrubPositionRef.value = 0;
            } else if (newValue >= rightVideoMetadata.value.FrameCount) {
                rightVideoScrubPositionRef.value = rightVideoMetadata.value.FrameCount - 1;
            } else {
                rightVideoScrubPositionRef.value = newValue;
            }
        }

        if (rightVideoPlayerInstance.value != null){
            let frameRate = 25;
            if (rightVideoMetadata.value != null && rightVideoMetadata.value.FrameRate != null) {
                frameRate = rightVideoMetadata.value.FrameRate;
            }
            rightVideoPlayerInstance.value.currentTime = rightVideoScrubPositionRef.value * (1.0 / frameRate);
        }
    };

    const leftVideoScrubPositionRef = ref(0);
    const leftVideoScrubPosition = computed({
        get: () => leftVideoScrubPositionRef.value,
        set: (newValue) => {
            if (newValue != leftVideoScrubPositionRef.value) {
                if (leftVideoPlayerInstance.value.src == null || leftVideoPlayerInstance.value.src.length <= 0 || (leftVideoPlayerInstance.value.readyState > 1 && !leftVideoPlayerInstance.value.seeking)){ // No video or Enough data for at least one frame is available and it is not currently seeking, so go there
                    setLeftScrubPositions(newValue);
                } else {
                    requestedLeftFrameIndex.value = newValue;
                    leftVideoWaitingForData.value = true;
                }
            }
        }
    });

    const rightVideoScrubPositionRef = ref(0);
    const rightVideoScrubPosition = computed({
        get: () => rightVideoScrubPositionRef.value,
        set: (newValue) => {
            if (newValue != rightVideoScrubPositionRef.value) {
                if (rightVideoPlayerInstance.value.src == null || rightVideoPlayerInstance.value.src.length <= 0 || (rightVideoPlayerInstance.value.readyState > 1 && !rightVideoPlayerInstance.value.seeking)){ //Enough data for at least one frame is available and it is not currently seeking, so go there
                    setRightScrubPositions(newValue);
                } else {
                    requestedRightFrameIndex.value = newValue;
                    rightVideoWaitingForData.value = true;
                }
            }
        }
    });

    const leftLabelScrubPositionRef = ref(0);
    const leftLabelScrubPosition = computed({
        get: () => leftLabelScrubPositionRef.value,
        set: (newValue) => {
            if (newValue != leftVideoScrubPositionRef.value) {
                if (leftVideoPlayerInstance.value.src == null || leftVideoPlayerInstance.value.src.length <= 0 || (leftVideoPlayerInstance.value.readyState > 1 && !leftVideoPlayerInstance.value.seeking)){ //Enough data for at least one frame is available and it is not currently seeking, so go there
                    setLeftScrubPositions(newValue);
                } else {
                    requestedLeftFrameIndex.value = newValue;
                    leftVideoWaitingForData.value = true;
                }
            }
        }
    });
    const rightLabelScrubPositionRef = ref(0);
    const rightLabelScrubPosition = computed({
        get: () => rightLabelScrubPositionRef.value,
        set: (newValue) => {
            if (newValue != rightVideoScrubPositionRef.value) {
                if (rightVideoPlayerInstance.value.src == null || rightVideoPlayerInstance.value.src.length <= 0 || (rightVideoPlayerInstance.value.readyState > 1 && !rightVideoPlayerInstance.value.seeking)){ //Enough data for at least one frame is available and it is not currently seeking, so go there
                    setRightScrubPositions(newValue);
                } else {
                    requestedRightFrameIndex.value = newValue;
                    rightVideoWaitingForData.value = true;
                }
            }
        }
    });

    const ZOOMSTEP = 0.1;

    const leftZoomLevelRef = ref(1);
    const leftZoomLevel = computed({
        get: () => Math.round(leftZoomLevelRef.value * 100) / 100,
        set: (newValue) => {
            if (newValue < ZOOMSTEP) leftZoomLevelRef.value = ZOOMSTEP;
            else leftZoomLevelRef.value = newValue;
        }
    });
    const rightZoomLevelRef = ref(1);
    const rightZoomLevel = computed({
        get: () => Math.round(rightZoomLevelRef.value * 100) / 100,
        set: (newValue) => {
            if (newValue < ZOOMSTEP) rightZoomLevelRef.value = ZOOMSTEP;
            else rightZoomLevelRef.value = newValue;
        }
    });

    const bothDatasetSize = computed(() => {
        return Math.min(leftDatasetSize.value, rightDatasetSize.value);
    });
    const bothScrubPositionRef = ref(0);
    const bothScrubPosition = computed({
        get: () => bothScrubPositionRef.value,
        set: (newValue) => {
            if (!isNaN(newValue)) {
                if (newValue < 0) {
                    bothScrubPositionRef.value = 0;
                } else if (newValue > bothDatasetSize.value) {
                    bothScrubPositionRef.value = bothDatasetSize.value;
                } else {
                    bothScrubPositionRef.value = newValue;
                }

                leftLabelScrubPosition.value = bothScrubPositionRef.value;
                rightLabelScrubPosition.value = bothScrubPositionRef.value;
            }
        }
    });

    const labelScaling = computed({
        get: () => store.getters['skeleton/getAnalysisScaling'],
        set: newScaling => {
            store.commit('skeleton/setAnalysisScaling', newScaling);
        }
    });
    const labelReference = computed({
        get: () => store.getters['skeleton/getAnalysisReference'],
        set: newReference => {
            store.commit('skeleton/setAnalysisReference', newReference);
        }
    });
    const labelEnableSettings = computed({
        get: () => store.getters['skeleton/getAnalysisEnableSettings'],
        set: newEnableSettings => {
            store.commit('skeleton/setAnalysisEnableSettings', newEnableSettings);
        }
    });
    //Array that contains sets of points to compare with their distance
    const labelSelection = computed({
        get: () => store.getters['skeleton/getAnalysisSelection'],
        set: newSelection => {
            store.commit('skeleton/setAnalysisSelection', newSelection);
        }
    });
    //Array that contains sets of points where just single coordinates should be compared
    const labelCoordinates = computed({
        get: () => store.getters['skeleton/getAnalysisCoordinates'],
        set: newCoordinates => {
            store.commit('skeleton/setAnalysisCoordinates', newCoordinates);
        }
    });
    const labelOptions = computed(() => {
        let labels = [];
        if (leftLabelFileStore.value != null) {
            labels = _.concat(labels, Object.keys(leftLabelFileStore.value));
        }
        if (rightLabelFileStore.value != null) {
            labels = _.concat(labels, Object.keys(rightLabelFileStore.value));
        }
        return _.uniq(labels);
    });
    const classificationThreshold = computed({
        get: () => store.getters['skeleton/getAnalysisThreshold'],
        set: newThreshold => {
            store.commit('skeleton/setAnalysisThreshold', newThreshold);
        }
    });

    const loadVideoFile = function(videoStore, videoFileInput){
        if (videoFileInput.files.length == 1){
            if (videoStore === 'left' && leftVideoPlayerInstance.value != null) {
                if (leftVideoPlayerInstance.value.src != null) URL.revokeObjectURL(leftVideoPlayerInstance.value.src);
                leftVideoFileStore.value = videoFileInput.files[0];
                leftVideoFileName.value = videoFileInput.files[0].name;
                if (leftVideoPlayerInstance.value.canPlayType(videoFileInput.files[0].type) !== '') {
                    getVideoMetadata(videoFileInput.files[0]).then((metadata) => leftVideoMetadata.value = getFrameInfoFromMetadata(metadata));
                    let videoURL = URL.createObjectURL(videoFileInput.files[0]);
                    leftVideoPlayerInstance.value.src = videoURL;
                } else {
                    localError(i18n, i18n.$t('tools.analyse-skeleton.file-type-not-supported'));
                    //TODO Reset on error
                    //////resetLabelingState();
                }

            } else if (videoStore === 'right' && rightVideoPlayerInstance.value != null) {
                if (rightVideoPlayerInstance.value.src != null) URL.revokeObjectURL(rightVideoPlayerInstance.value.src);
                rightVideoFileStore.value = videoFileInput.files[0];
                rightVideoFileName.value = videoFileInput.files[0].name;
                if (rightVideoPlayerInstance.value.canPlayType(videoFileInput.files[0].type) !== '') {
                    getVideoMetadata(videoFileInput.files[0]).then((metadata) => rightVideoMetadata.value = getFrameInfoFromMetadata(metadata));
                    let videoURL = URL.createObjectURL(videoFileInput.files[0]);
                    rightVideoPlayerInstance.value.src = videoURL;
                } else {
                    localError(i18n, i18n.$t('tools.analyse-skeleton.file-type-not-supported'));
                    //TODO Reset on error
                    //////resetLabelingState();
                }
            }
        }
    }

    /*const resetLabelingState = function(){
      fileChooser.value.value = null; 
      videoFile.value = null;
      videoPlayerInstance.value.removeAttribute('src');
      videoPlayerInstance.value.load();
      videoLength.value = 0;
      resetFrameParameters();
    }

    const resetFrameParameters = function(){
      loadedFramesMetadata.value = null;
      canvasSourceSize.value = null;
      zoomLevel.value = 1;
    }*/

    watch([leftVideoFileName, leftVideoDimensions, leftVideoMetadata], ([newVideoFilename, newVideoDimensions, newVideoMetadata], [oldVideoFilename]) => {
        if (newVideoDimensions != null && newVideoMetadata != null) {
            if (newVideoFilename != oldVideoFilename) { //Only update scrub when the video changes to not trigger that on a reload
                leftVideoScrubPosition.value = 10;
            } else {
                setLeftScrubPositions(leftVideoScrubPosition.value); //Reloaded video must be scrubbed to same point
            }
        } 
    });

    watch([rightVideoFileName, rightVideoDimensions, rightVideoMetadata], ([newVideoFilename, newVideoDimensions, newVideoMetadata], [oldVideoFilename]) => {
        if (newVideoDimensions != null && newVideoMetadata != null) {
            if (newVideoFilename != oldVideoFilename) { //Only update scrub when the video changes to not trigger that on a reload
                rightVideoScrubPosition.value = 10;
            } else {
                setRightScrubPositions(rightVideoScrubPosition.value); //Reloaded video must be scrubbed to same point
            }
        }
    });

    const loadedVideoMetadata = function(videoStore){
        if (videoStore === 'left' && leftVideoPlayerInstance.value != null) {
            leftVideoDimensions.value = { 'width': leftVideoPlayerInstance.value.videoWidth, 'height': leftVideoPlayerInstance.value.videoHeight };
        } else if (videoStore === 'right' && rightVideoPlayerInstance.value != null) {
            rightVideoDimensions.value = { 'width': rightVideoPlayerInstance.value.videoWidth, 'height': rightVideoPlayerInstance.value.videoHeight };
        }
    }

    const calculatePointDistance = function(pointA, pointB){
        if (pointA != null && pointA.x != null && pointA.y != null && 
            pointB != null && pointB.x != null && pointB.y != null){
                return Math.hypot(pointB.x-pointA.x, pointB.y-pointA.y);
            }
        return null;
    }

    const graphColors = computed(() => {
        if (store.getters.isDarkModeActive) {
            return ({
                grid: 'rgb(71, 72, 75)',
                text: 'rgb(244, 245, 248)'
            });
        } else {
            return ({
                grid: 'rgb(197, 200, 211)',
                text: 'rgb(34, 36, 40)'
            });
        }
    });

    const applyColorsToGraph = function(colors){
        if (leftChartJS != null){
            leftChartJS.config.options.scales['x'].grid.color =
            leftChartJS.config.options.scales['y'].grid.color = colors.grid;

            leftChartJS.config.options.scales['x'].ticks.color =
            leftChartJS.config.options.scales['y'].ticks.color = colors.text;

            leftChartJS.config.options.plugins.legend.labels.color = 
            leftChartJS.config.options.plugins.legend.title.color = colors.text;

            leftChartJS.update('none');
        }
        if (rightChartJS != null){
            rightChartJS.config.options.scales['x'].grid.color =
            rightChartJS.config.options.scales['y'].grid.color = colors.grid;

            rightChartJS.config.options.scales['x'].ticks.color =
            rightChartJS.config.options.scales['y'].ticks.color = colors.text;

            rightChartJS.config.options.plugins.legend.labels.color = 
            rightChartJS.config.options.plugins.legend.title.color = colors.text;
            rightChartJS.update('none');
        }
    }

    watch(graphColors, (newColors) => applyColorsToGraph(newColors));

    const createDatasets = function(labelFileStore, selection, reference, threshold, singleCoordinates, enableSettings){
        let datasets = [];
        let colorIndex = 0;
        if (labelFileStore != null){
            //Calculate all the point distances
            if (enableSettings.comparison) {
                for (let comparison of selection) {                
                    if (comparison.length == 2 && comparison[0] != null && comparison[1] != null) {
                        let color = createRGBColorString(colorIndex++);
                        let dataset = {
                            label: `${comparison[0]} - ${comparison[1]}`,
                            backgroundColor: color,
                            borderColor: color,
                            data: []
                        }

                        if (comparison[0] in labelFileStore && comparison[1] in labelFileStore){
                            for (let i = 0; i < Math.min(labelFileStore[comparison[0]].length, labelFileStore[comparison[1]].length); i++) {
                                if (labelFileStore[comparison[0]][i]['likelihood'] > threshold && labelFileStore[comparison[1]][i]['likelihood'] > threshold) {
                                    let referenceScalingFactor = 1;
                                    if (reference.length == 2 && reference[0] in labelFileStore && reference[1] in labelFileStore) {
                                        if (labelFileStore[reference[0]][i]['likelihood'] > threshold && labelFileStore[reference[1]][i]['likelihood'] > threshold) {
                                            referenceScalingFactor = calculatePointDistance(labelFileStore[reference[0]][i], labelFileStore[reference[1]][i])
                                        } else {
                                            dataset.data.push({
                                                x: i,
                                                y: null
                                            });
                                            continue;
                                        }
                                    }
                                    dataset.data.push({
                                        x: i,
                                        y: calculatePointDistance(labelFileStore[comparison[0]][i], labelFileStore[comparison[1]][i]) / referenceScalingFactor
                                    });
                                } else {
                                    dataset.data.push({
                                        x: i,
                                        y: null
                                    });
                                }
                            }
                            datasets.push(dataset);
                        }
                    }
                }
            }

            //Add all the points with single coordinates
            if (enableSettings.x || enableSettings.y) { //X and Y coordinates can be enabled separately, if one of them is used, go through the coordinates
                for (let coordinate of singleCoordinates) {                
                    if (coordinate.name != null && (coordinate.x != null || coordinate.y != null)) {
                        let datasetX = null;
                        let datasetY = null;
                        if (enableSettings.x && coordinate.x != null) {
                            let color = createRGBColorString(colorIndex++);
                            datasetX = {
                                label: `X: ${coordinate.name}`,
                                backgroundColor: color,
                                borderColor: color,
                                data: []
                            }
                        }
                        if (enableSettings.y && coordinate.y != null) {
                            let color = createRGBColorString(colorIndex++);
                            datasetY = {
                                label: `Y: ${coordinate.name}`,
                                backgroundColor: color,
                                borderColor: color,
                                data: []
                            }
                        }

                        if (coordinate.name in labelFileStore){
                            for (let i = 0; i < labelFileStore[coordinate.name].length; i++) {
                                if (labelFileStore[coordinate.name][i]['likelihood'] > threshold) {
                                    let referenceScalingFactor = 1;
                                    if (reference.length == 2 && reference[0] in labelFileStore && reference[1] in labelFileStore) {
                                        if (labelFileStore[reference[0]][i]['likelihood'] > threshold && labelFileStore[reference[1]][i]['likelihood'] > threshold) {
                                            referenceScalingFactor = calculatePointDistance(labelFileStore[reference[0]][i], labelFileStore[reference[1]][i])
                                        } else {
                                            referenceScalingFactor = null;
                                        }
                                    }
                                    //Add the X-Axis if it is enabled and requested
                                    if (datasetX != null) {
                                        let data = {
                                            x: i,
                                            y: parseFloat(labelFileStore[coordinate.name][i].x)
                                        };
                                        if (coordinate.relativeTo != null) { //Requested that it is calculated given to a relative point on the body
                                            if (data.y != null && coordinate.relativeTo in labelFileStore && labelFileStore[coordinate.relativeTo][i]['likelihood'] > threshold) {
                                                data.y = data.y - labelFileStore[coordinate.relativeTo][i].x;
                                            } else {
                                                data.y = null;
                                            }
                                        }
                                        if (coordinate.x.scaling) { //Requested to scale to the given body reference length
                                            if (data.y != null && referenceScalingFactor != null) {
                                                data.y = data.y / referenceScalingFactor;
                                            } else {
                                                data.y = null;
                                            }
                                        }
                                        datasetX.data.push(data);
                                    }
                                    //Add the Y-Axis if it is enabled and requested
                                    if (datasetY != null) {
                                        let data = {
                                            x: i,
                                            y: parseFloat(labelFileStore[coordinate.name][i].y)
                                        };
                                        if (coordinate.relativeTo != null) { //Requested that it is calculated given to a relative point on the body
                                            if (data.y != null && coordinate.relativeTo in labelFileStore && labelFileStore[coordinate.relativeTo][i]['likelihood'] > threshold) {
                                                data.y = data.y - labelFileStore[coordinate.relativeTo][i].y;
                                            } else {
                                                data.y = null;
                                            }
                                        }
                                        if (coordinate.y.scaling) { //Requested to scale to the given body reference length
                                            if (data.y != null && referenceScalingFactor != null) {
                                                data.y = data.y / referenceScalingFactor;
                                            } else {
                                                data.y = null;
                                            }
                                        }
                                        datasetY.data.push(data);
                                    }
                                } else {
                                    if (datasetX != null) datasetX.data.push({
                                        x: i,
                                        y: null
                                    });
                                    if (datasetY != null) datasetY.data.push({
                                        x: i,
                                        y: null
                                    });
                                }
                            }
                            //Add the non-null datasets to the whole dataset list
                            if (datasetX != null) datasets.push(datasetX);
                            if (datasetY != null) datasets.push(datasetY);
                        }
                    }
                }
            }
        }
        
        return datasets;
    }

    watch([leftLabelFileStore, labelSelection, labelReference, classificationThreshold, labelCoordinates, labelEnableSettings], ([newLabelFileStore, newSelection, newReference, newThreshold, newCoordinates, newEnableSettings]) => {
        leftDatasets.value = createDatasets(newLabelFileStore, newSelection, newReference, newThreshold, newCoordinates, newEnableSettings);
        leftChartJS.data = { 
            datasets: leftDatasets.value
        };
        //Set min and max to properly show scale with no space on both sides of the scale
        leftChartJS.config.options.scales['x'].min = leftChartJS.config.options.plugins.zoom.limits['x'].min = 0;
        leftChartJS.config.options.scales['x'].max = leftChartJS.config.options.plugins.zoom.limits['x'].max = leftDatasetSize.value;
        leftChartJS.config.options.scales['y'].suggestedMin = undefined;
        leftChartJS.config.options.scales['y'].suggestedMax = undefined;
        leftChartJS.zoom(1.0); //Set to 1 for initial zoom - Fixes resetZoom resetting to 0
        leftChartJS.resetZoom(); //Zoom reset triggers data decimation
        leftChartJS.update();
    });

    watch([rightLabelFileStore, labelSelection, labelReference, classificationThreshold, labelCoordinates, labelEnableSettings], ([newLabelFileStore, newSelection, newReference, newThreshold, newCoordinates, newEnableSettings]) => {
        rightDatasets.value = createDatasets(newLabelFileStore, newSelection, newReference, newThreshold, newCoordinates, newEnableSettings);
        rightChartJS.data = { 
            datasets: rightDatasets.value
        };
        //Set min and max to properly show scale with no space on both sides of the scale
        rightChartJS.config.options.scales['x'].min = rightChartJS.config.options.plugins.zoom.limits['x'].min = 0;
        rightChartJS.config.options.scales['x'].max = rightChartJS.config.options.plugins.zoom.limits['x'].max = rightDatasetSize.value;
        rightChartJS.config.options.scales['y'].suggestedMin = undefined;
        rightChartJS.config.options.scales['y'].suggestedMax = undefined;
        rightChartJS.zoom(1.0); //Set to 1 for initial zoom - Fixes resetZoom resetting to 0
        rightChartJS.resetZoom(); //Zoom reset triggers data decimation
        rightChartJS.update();
    });

    const loadedLabelFile = function(labelStore, labelFileContents) {
        if (labelFileContents.length > 3) {
            let finalDataObject = {};

            let bodyparts = labelFileContents[1];
            let coordinates = labelFileContents[2];

            for (let rowIndex = 3; rowIndex < labelFileContents.length; rowIndex++){
                let values = labelFileContents[rowIndex];
                let rowNumber = values[0];
                for (let columnIndex = 1; columnIndex < Math.min(bodyparts.length, coordinates.length, values.length); columnIndex++){
                    //Create an array for each bodypart, if it does not yet exist
                    if (finalDataObject[bodyparts[columnIndex]] == null) {
                        finalDataObject[bodyparts[columnIndex]] = [];
                    }
                    //Create an object for each frame index, if it does not yet exist
                    if (finalDataObject[bodyparts[columnIndex]][rowNumber] == null) {
                        finalDataObject[bodyparts[columnIndex]][rowNumber] = { 'index': rowNumber };
                    }
                    finalDataObject[bodyparts[columnIndex]][rowNumber][coordinates[columnIndex]] = values[columnIndex];
                }
            }

            if (labelStore === 'left') {
                leftLabelFileStore.value = finalDataObject;
                leftDatasetSize.value = labelFileContents.length - 4;
                leftLabelScrubPosition.value = 10;
            } else if (labelStore === 'right') {
                rightLabelFileStore.value = finalDataObject;
                rightDatasetSize.value = labelFileContents.length - 4;
                rightLabelScrubPosition.value = 10;
            }
        } else {
            localError(i18n, i18n.$t('tools.analyse-skeleton.label-file-invalid'));
        }
    }

    const loadLabelFile = function(labelStore, labelFileInput){
        //TODO Reset file input?
        if (labelFileInput.files.length == 1){
            if (labelStore === 'left') {
                leftLabelScrubPosition.value = 0;
                leftLabelFileName.value = labelFileInput.files[0].name;
            } else if (labelStore === 'right') {
                rightLabelScrubPosition.value = 0;
                rightLabelFileName.value = labelFileInput.files[0].name;
            }

            try {
                Papa.parse(labelFileInput.files[0], {
                    complete: function(results) {
                        loadedLabelFile(labelStore, results.data);
                    }
                });
            } catch {
                localError(i18n, i18n.$t('tools.analyse-skeleton.label-file-invalid'));
            }
        }
    }

    const exportData = function(labelStore){
        let datasets = null;
        let filename = 'AnalysisGraph'
        if (labelStore === 'left') {
            datasets = leftDatasets.value;
            filename += '_' + _.truncate(leftLabelFileName.value);
        } else if (labelStore === 'right') {
            datasets = rightDatasets.value;
            filename += '_' + _.truncate(rightLabelFileName.value);
        }

        if (datasets != null) {
            let exportHeader = ['index'];
            let exportArray = [];
            for (let dataset of datasets){
                exportHeader.push(dataset.label);
                //If the data is decimated, use the original data for export
                let dataArray;
                if (dataset._originalData != null) {
                    dataArray = dataset._originalData;
                } else {
                    dataArray = dataset.data;
                }
                exportArray.push(dataArray.map((dataEntry) => {
                    if (dataEntry.y != null && dataEntry.y.toFixed) {
                        return dataEntry.y.toFixed(4).replace(".", ",") //Convert it to a shorter string with correct excel format
                    } else {
                        return null;
                    }
                })
                ); 
            }

            let indexCount = Math.max(...exportArray.map((array) => array.length));
            let indizes = Array.from(Array(indexCount), (e,i) => i);
            
            let exportStringComponent = Papa.unparse({fields: exportHeader, data: _.zip(indizes, ...exportArray) /* Transpose */}, {delimiter: ';'});
            let exportString = 'data:text/csv;charset=utf-8,' + encodeURIComponent(exportStringComponent);

            downloadElement.value.setAttribute('href', exportString);
            downloadElement.value.setAttribute('download', filename + '_' + exportArray.length + '.csv')
            downloadElement.value.click();
        }
    }

    const resetZoom = function(labelStore){
        if (labelStore === 'left' && leftChartJS != null) {
            leftChartJS.resetZoom();
        } else if (labelStore === 'right' && rightChartJS != null) {
            rightChartJS.resetZoom();
        }
    }

    const toggleTooltip = function(labelStore){
        if (labelStore === 'left' && leftChartJS != null) {
            leftChartJS.config.options.plugins.annotation.annotations.line.label.enabled = leftTooltipEnabled.value = !leftTooltipEnabled.value;
            leftChartJS.update('none');
        } else if (labelStore === 'right' && rightChartJS != null) {
            rightChartJS.config.options.plugins.annotation.annotations.line.label.enabled = rightTooltipEnabled.value = !rightTooltipEnabled.value;
            rightChartJS.update('none');
        }
    }

    const toggleLegend = function(labelStore){
        if (labelStore === 'left' && leftChartJS != null) {
            leftChartJS.config.options.plugins.legend.display = leftLegendEnabled.value = !leftLegendEnabled.value;
            leftChartJS.update('none');
        } else if (labelStore === 'right' && rightChartJS != null) {
            rightChartJS.config.options.plugins.legend.display = rightLegendEnabled.value = !rightLegendEnabled.value;
            rightChartJS.update('none');
        }
    }

    const createSingleFrame = function(scaling, labelFileStore, index, threshold, singleCoordinates, enableSettings) {
        let frame = {
            skeleton: {
            }
        }
        let colorIndex = 0;
        if (labelFileStore != null){
            //Point distance comparison
            if (enableSettings.comparison) {
                for (let comparisonIndex = 0; comparisonIndex < labelSelection.value.length; comparisonIndex++) {
                    let comparison = labelSelection.value[comparisonIndex];
                    
                    if (comparison.length == 2 && comparison[0] != null && comparison[1] != null) {
                        let color = createHexColorString(colorIndex++);

                        if (comparison[0] in labelFileStore && comparison[1] in labelFileStore){
                            if (labelFileStore[comparison[0]][index] != null && labelFileStore[comparison[1]][index] != null && 
                                labelFileStore[comparison[0]][index]['likelihood'] > threshold && labelFileStore[comparison[1]][index]['likelihood'] > threshold) {
                                if (!(comparison[0] in frame.skeleton)) {
                                    frame.skeleton[comparison[0]] = {
                                        ..._.cloneDeep(labelFileStore[comparison[0]][index]),
                                        connectedTo: [],
                                        color: color
                                    };
                                    frame.skeleton[comparison[0]].x *= scaling;
                                    frame.skeleton[comparison[0]].y *= scaling;
                                }
                                if (!(comparison[1] in frame.skeleton)) {
                                    frame.skeleton[comparison[1]] = {
                                        ..._.cloneDeep(labelFileStore[comparison[1]][index]),
                                        connectedTo: [],
                                        color: color
                                    };
                                    frame.skeleton[comparison[1]].x *= scaling;
                                    frame.skeleton[comparison[1]].y *= scaling;
                                }

                                if (comparison[0] in frame.skeleton)
                                    frame.skeleton[comparison[0]].connectedTo.push(comparison[1]);
                            }
                        }
                    }
                }
            }

            //Points with single coordinates
            if (enableSettings.x || enableSettings.y) { //X and Y coordinates can be enabled separately, if one of them is used, go through the coordinates
                for (let coordinate of singleCoordinates) {                
                    if (coordinate.name != null && (coordinate.x != null || coordinate.y != null)) {
                        let color = null;
                        //Create both colors to keep the index consistent, prefer color of X
                        if (enableSettings.x && coordinate.x != null) {
                            color = createHexColorString(colorIndex++);
                        }
                        if (enableSettings.y && coordinate.y != null) {
                            if (color == null) {
                                color = createHexColorString(colorIndex++);
                            } else {
                                colorIndex++;
                            }
                        }

                        if (coordinate.name in labelFileStore){
                            if (labelFileStore[coordinate.name][index] != null && labelFileStore[coordinate.name][index]['likelihood'] > threshold) {
                                if (!(coordinate.name in frame.skeleton)) {
                                    frame.skeleton[coordinate.name] = {
                                        ..._.cloneDeep(labelFileStore[coordinate.name][index]),
                                        connectedTo: [],
                                        color: color
                                    };
                                    frame.skeleton[coordinate.name].x *= scaling;
                                    frame.skeleton[coordinate.name].y *= scaling;
                                }
                            }
                        }
                    }
                }
            }

            return frame;
        }
        return null;
    }

    const leftVisibleFrame = computed(() => {
        return createSingleFrame(labelScaling.value, leftLabelFileStore.value, leftLabelScrubPosition.value, classificationThreshold.value, labelCoordinates.value, labelEnableSettings.value);
    });
    const rightVisibleFrame = computed(() => {
        return createSingleFrame(labelScaling.value, rightLabelFileStore.value, rightLabelScrubPosition.value, classificationThreshold.value, labelCoordinates.value, labelEnableSettings.value);
    });

    function decimateData(labelStore) {
        let chart = null;
        if (labelStore === 'left') {
            chart = leftChartJS;
        } else if (labelStore === 'right') {
            chart = rightChartJS;
        }

        //Adapted from Chart.js dataDecimation plugin:
        if (chart != null){
            let zoomLevel = (chart.getZoomLevel != null && chart.getZoomLevel() != 0) ? chart.getZoomLevel() : 1;
            let calculatedWidth = (chart.width * zoomLevel) / 2; //Half of the available space should be used for data. When zoomed in then more data should be added to the dataset

            chart.data.datasets.forEach((dataset) => {
                if (dataset._originalData == null) {
                    // First time we are seeing this dataset
                    // We override the 'data' property with a setter that stores the
                    // raw data in _originalData, but reads the decimated data from _decimatedData
                    dataset._originalData = dataset.data;
                    delete dataset.data;
                    Object.defineProperty(dataset, 'data', {
                        configurable: true,
                        enumerable: true,
                        get: function() {
                            return this._decimatedData;
                        },
                        set: function(d) {
                            this._originalData = d;
                        }
                    });
                }

                dataset._decimatedData = minMaxDecimation(dataset._originalData, calculatedWidth);
            });
        }
    }

    const calculateAnnotationFontSize = function(context, chart){
        const defaultFontsize = 12;
        const minimumFontsize = 8;
        const reducedPadding = 2; //Padding to use, when the font shrinks
        if (chart != null && context.element.options && context.element.options.label && context.element.options.label.content && Array.isArray(context.element.options.label.content)) {
            let availableHeightPerLine = chart.height / (context.element.options.label.content.length);
            let neededFontsize = (availableHeightPerLine - reducedPadding) / context.element.options.label.font.lineHeight; //Scales to line height and includes padding per line

            if (neededFontsize <= defaultFontsize) { //Font is shrinking
                //Set padding to better fit more text
                chart.config.options.plugins.annotation.annotations.line.label.padding = {
                    y: reducedPadding
                };
                chart.config.options.plugins.annotation.annotations.line.label.yPadding = reducedPadding;
                
                if (neededFontsize < minimumFontsize) { //Font would be too small, cap it
                    return minimumFontsize;
                } else {
                    return neededFontsize;
                }
            } else {
                return defaultFontsize;
            }
        } else {
            return defaultFontsize;
        }
    }

    const configLeft = {
        type: 'line',
        data: {
            datasets: []
        },
        options: {
            indexAxis: 'x',
            elements: {
                point: {
                    radius: 0
                }
            },
            layout: {
                autoPadding: false
            },
            maintainAspectRatio: false,
            parsing: false,
            normalized: true,
            onResize: (chart) => { //Re-Decimate data on resize to show more or less data depending on available space
                decimateData('left');
                chart.update('none');
            },
            resizeDelay: RESIZE_DEBOUNCE, //Debounce resize update
            plugins: {
                autocolors: true,
                annotation: {
                    annotations: {
                        line: {
                            type: 'line',
                            xMin: 0,
                            xMax: 0,
                            borderColor: 'rgb(56, 128, 255)',
                            borderWidth: 2,
                            label: {
                                enabled: leftTooltipEnabled.value,
                                position: 'end',
                                textAlign: 'start',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                font: {
                                    size: (context) => calculateAnnotationFontSize(context, leftChartJS)
                                }
                            }
                        }
                    }
                },
                legend: {
                    display: leftLegendEnabled.value,
                    labels: {
                        color: graphColors.value.text
                    }, 
                    title: {
                        color: graphColors.value.text
                    }
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                        onZoomComplete: (context) => { //Re-Decimate data on zoom to show more or less data
                            //Use a timeout as a debounce
                            clearTimeout(leftZoomTimeout);
                            leftZoomTimeout = setTimeout(() => {
                                decimateData('left');
                                context.chart.update('none');
                            }, ZOOM_DEBOUNCE);
                        },
                    },
                    limits: {
                        x: {min: 0, max: 0}
                    },
                    pan: {
                        enabled: true,
                        mode: 'x',
                    }
                }
            },
            scales: {
                x: {
                    type: 'linear',
                    grid: {
                        color: graphColors.value.grid
                    },
                    ticks: {
                        callback: (value, index, values) => {
                            if (index == 0 || index == (values.length-1)) { //Hide first and last ticks, that are just the invisible bounds
                                return undefined;
                            }
                            return value.toFixed(0); //Convert it to integer
                        },
                        color: graphColors.value.text,
                        maxTicksLimit: 50,
                        maxRotation: 0
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 0
                },
                y: {
                    type: 'linear',
                    afterUpdate: (axis) => {
                        if (rightChartJS != null) {
                            if (rightChartJS.config.options.scales['y'].suggestedMin != axis.start || rightChartJS.config.options.scales['y'].suggestedMax != axis.end) {
                                rightChartJS.config.options.scales['y'].suggestedMin = axis.start;
                                rightChartJS.config.options.scales['y'].suggestedMax = axis.end;
                                rightChartJS.update('none');
                            }
                        }
                    },
                    grid: {
                        color: graphColors.value.grid
                    },
                    ticks: {
                        color: graphColors.value.text
                    }
                }
            }
        }
    };

    const configRight = {
        type: 'line',
        data: {
            datasets: []
        },
        options: {
            indexAxis: 'x',
            elements: {
                point: {
                    radius: 0
                }
            },
            layout: {
                autoPadding: false
            },
            maintainAspectRatio: false,
            parsing: false,
            normalized: true,
            onResize: (chart) => { //Re-Decimate data on resize to show more or less data depending on available space
                decimateData('right');
                chart.update('none');
            },
            resizeDelay: RESIZE_DEBOUNCE, //Debounce resize update
            plugins: {
                autocolors: true,
                annotation: {
                    annotations: {
                        line: {
                            type: 'line',
                            xMin: 0,
                            xMax: 0,
                            borderColor: 'rgb(56, 128, 255)',
                            borderWidth: 2,
                            label: {
                                enabled: rightTooltipEnabled.value,
                                position: 'end',
                                textAlign: 'start',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                font: {
                                    size: (context) => calculateAnnotationFontSize(context, rightChartJS)
                                }
                            }
                        }
                    }
                },
                legend: {
                    display: rightLegendEnabled.value,
                    labels: {
                        color: graphColors.value.text
                    }, 
                    title: {
                        color: graphColors.value.text
                    }
                },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x',
                        onZoomComplete: (context) => { //Re-Decimate data on zoom to show more or less data
                            //Use a timeout as a debounce
                            clearTimeout(rightZoomTimeout);
                            rightZoomTimeout = setTimeout(() => {
                                decimateData('right');
                                context.chart.update('none');
                            }, ZOOM_DEBOUNCE);
                        },
                    },
                    limits: {
                        x: {min: 0, max: 0}
                    },
                    pan: {
                        enabled: true,
                        mode: 'x',
                    }
                }
            },
            scales: {
                x: {
                    type: 'linear',
                    grid: {
                        color: graphColors.value.grid
                    },
                    ticks: {
                        callback: (value, index, values) => {
                            if (index == 0 || index == (values.length-1)) { //Hide first and last ticks, that are just the invisible bounds
                                return undefined;
                            }
                            return value.toFixed(0); //Convert it to integer
                        },
                        color: graphColors.value.text,
                        maxTicksLimit: 50,
                        maxRotation: 0
                    },
                    beginAtZero: true,
                    min: 0,
                    max: 0
                },
                y: {
                    type: 'linear',
                    afterUpdate: (axis) => {
                        if (leftChartJS != null) {
                            if (leftChartJS.config.options.scales['y'].suggestedMin != axis.start || leftChartJS.config.options.scales['y'].suggestedMax != axis.end) {
                                leftChartJS.config.options.scales['y'].suggestedMin = axis.start;
                                leftChartJS.config.options.scales['y'].suggestedMax = axis.end;
                                leftChartJS.update('none');
                            }
                        }
                    },
                    grid: {
                        color: graphColors.value.grid
                    },
                    ticks: {
                        color: graphColors.value.text
                    }
                }
            }
        }
    };

    const chooseLabels = function(){
        openPointComparisonSelectModal(modalComponent, labelScaling.value, labelReference.value, labelSelection.value, labelOptions.value, classificationThreshold.value, labelCoordinates.value, labelEnableSettings.value)
            .then((data) => {
                if (data != null && data.data != null){
                    if (data.data.scaling != null) labelScaling.value = data.data.scaling;
                    if (data.data.reference != null) labelReference.value = data.data.reference;
                    if (data.data.selection != null) labelSelection.value = data.data.selection;
                    if (data.data.threshold != null) classificationThreshold.value = data.data.threshold;
                    if (data.data.coordinates != null) labelCoordinates.value = data.data.coordinates;
                    if (data.data.enableSettings != null) labelEnableSettings.value = data.data.enableSettings;
                }
            });
    }

    //Additional status values for video playback. Indicates that a video is waiting for a frame to become available
    const leftVideoWaitingForData = ref(false);
    const rightVideoWaitingForData = ref(false);

    /*  When the video does not have a frame ready, the update of the scrub position indicates that it is waiting. 
        When data is available a separate listener for "canplay" then resets it, so we can actually proceed to the requested frame here. 
    */
    watch([leftVideoWaitingForData, requestedLeftFrameIndex], ([isWaiting, requestedFrameIndex]) => {
        if (isWaiting === false && requestedFrameIndex != null){
            setLeftScrubPositions(requestedFrameIndex);
            requestedLeftFrameIndex.value = null;
        }
    });
    watch([rightVideoWaitingForData, requestedRightFrameIndex], ([isWaiting, requestedFrameIndex]) => {
        if (isWaiting === false && requestedFrameIndex != null){
            setRightScrubPositions(requestedFrameIndex);
            requestedRightFrameIndex.value = null;
        }
    });

    const playPause = function(videoStore){
        let frameRate = 25;
        if (videoStore === 'left') {
            if (leftPlayInterval.value != null || leftVideoPlayerInstance.value == null) {
                clearInterval(leftPlayInterval.value);
                leftPlayInterval.value = null;
            } else {
                if (leftVideoMetadata.value != null){
                    if (leftVideoMetadata.value.FrameRate != null) {
                        frameRate = leftVideoMetadata.value.FrameRate;
                    }
                    if (leftVideoMetadata.value.FrameCount != null) {
                        leftPlayInterval.value = setInterval(() => {
                            if ((leftVideoScrubPosition.value + 1) >= parseInt(leftVideoMetadata.value.FrameCount) - 1){
                                clearInterval(leftPlayInterval.value);
                                leftPlayInterval.value = null;
                            }

                            leftVideoScrubPosition.value++;
                        }, (1.0 / frameRate) * 1000); //TODO Make playback speed adjustable
                    }
                }
            }
        } else if (videoStore === 'right') {
            if (rightPlayInterval.value != null || rightVideoPlayerInstance.value == null) {
                clearInterval(rightPlayInterval.value);
                rightPlayInterval.value = null;
            } else {
                if (rightVideoMetadata.value != null){
                    if (rightVideoMetadata.value.FrameRate != null) {
                        frameRate = rightVideoMetadata.value.FrameRate;
                    }
                    if (rightVideoMetadata.value.FrameCount != null) {
                        rightPlayInterval.value = setInterval(() => {
                            if ((rightVideoScrubPosition.value + 1) >= parseInt(rightVideoMetadata.value.FrameCount) - 1){
                                clearInterval(rightPlayInterval.value);
                                rightPlayInterval.value = null;
                            }

                            rightVideoScrubPosition.value++;
                        }, (1.0 / frameRate) * 1000); //TODO Make playback speed adjustable
                    }
                }
            }
        }
    }

    const updateAnnotation = function(chartInstance, newPosition, datasets) {
        chartInstance.config.options.plugins.annotation.annotations.line.xMin = 
        chartInstance.config.options.plugins.annotation.annotations.line.xMax = newPosition;

        let annotationLabel = [newPosition];
        if (datasets != null){
            for (let dataset of datasets) {
                //If the data is decimated, use the original data for export
                let dataArray;
                if (dataset._originalData != null) {
                    dataArray = dataset._originalData;
                } else {
                    dataArray = dataset.data;
                }
                annotationLabel.push(dataset.label + ': ' + 
                    ((dataArray[newPosition] != null && dataArray[newPosition].y != null) ? dataArray[newPosition].y.toFixed(2) : '-')
                );
            }
        }

        chartInstance.config.options.plugins.annotation.annotations.line.label.content = annotationLabel;

        setTimeout(() => chartInstance.update('none'), 0); //Call in async thread to not disturb all other tasks - Update is heavy
    }

    //FIXME There are memory leaks somewhere in the code because it uses MBs of memory???

    watch(leftLabelScrubPosition, (newPosition) => {
        updateAnnotation(leftChartJS, newPosition, leftDatasets.value);
    });
    watch(leftDatasets, () => { //Watch for changes in the dataset and call the update in addition to scrub position change
        updateAnnotation(leftChartJS, leftLabelScrubPosition.value, leftDatasets.value);
    });

    watch(rightLabelScrubPosition, (newPosition) => {
        updateAnnotation(rightChartJS, newPosition, rightDatasets.value);
    });
    watch(rightDatasets, () => { //Watch for changes in the dataset and call the update in addition to scrub position change
        updateAnnotation(rightChartJS, rightLabelScrubPosition.value, rightDatasets.value);
    });

    const remountVideoOnVisibilityChange = function() {
        if (document.visibilityState === 'visible') {
            if (leftVideoPlayerInstance.value != null) leftVideoPlayerInstance.value.load();
            if (rightVideoPlayerInstance.value != null) rightVideoPlayerInstance.value.load();
        }
    }

    onMounted(() => {
        leftChartJS = new Chart(
            leftChart.value,
            configLeft
        );

        rightChartJS = new Chart(
            rightChart.value,
            configRight
        );

        //Indicate that the next frame is ready
        leftVideoPlayerInstance.value.oncanplay = () => { 
            leftVideoWaitingForData.value = false;
        };
        leftVideoPlayerInstance.value.addEventListener('seeked', leftVideoPlayerInstance.value.oncanplay); //Add seeked for Safari

        rightVideoPlayerInstance.value.oncanplay = () => {
            rightVideoWaitingForData.value = false;
        };
        rightVideoPlayerInstance.value.addEventListener('seeked', rightVideoPlayerInstance.value.oncanplay); //Add seeked for Safari

        window.addEventListener('focus', remountVideoOnVisibilityChange);
    });

    onUnmounted(() => {
        window.removeEventListener('focus', remountVideoOnVisibilityChange);
        if (leftVideoPlayerInstance.value != null && leftVideoPlayerInstance.value.src != null) URL.revokeObjectURL(leftVideoPlayerInstance.value.src);
        if (rightVideoPlayerInstance.value != null && rightVideoPlayerInstance.value.src != null) URL.revokeObjectURL(rightVideoPlayerInstance.value.src);
    });

    return {
        i18n,
        ZOOMSTEP,
        leftChart,
        rightChart,
        graphVisible,
        downloadElement,
        leftVideoFrameCount,
        rightVideoFrameCount,
        bothScrubPosition,
        leftVideoScrubPosition,
        rightVideoScrubPosition,
        leftLabelScrubPosition,
        rightLabelScrubPosition,
        leftVideoFileChooser,
        leftVideoFileName,
        leftVideoFileStore,
        leftVideoPlayerInstance,
        leftVideoDimensions,
        rightVideoFileChooser,
        rightVideoFileName,
        rightVideoFileStore,
        rightVideoPlayerInstance,
        rightVideoDimensions,
        leftVisibleFrame,
        leftZoomLevel,
        rightVisibleFrame,
        rightZoomLevel,
        leftLabelFileChooser,
        leftLabelFileName,
        leftDatasetSize,
        leftLabelFileStore,
        leftDatasets,
        rightLabelFileChooser,
        rightLabelFileName,
        rightDatasetSize,
        rightLabelFileStore,
        rightDatasets,
        bothDatasetSize,
        leftPointCanvasInstance,
        rightPointCanvasInstance,
        leftTooltipEnabled,
        rightTooltipEnabled,
        leftLegendEnabled,
        rightLegendEnabled,
        loadVideoFile,
        loadLabelFile,
        chooseLabels,
        loadedVideoMetadata,
        playPause,
        exportData,
        toggleTooltip,
        toggleLegend,
        resetZoom,
        leftPlayInterval,
        rightPlayInterval,

        analytics,
        film,
        playSkipBack,
        playSkipForward,
        barChart,
        addCircle,
        removeCircle,
        expand,
        play,
        pause,
        save,
        information,
        ellipsisHorizontal,
        list
    };
  }
}
</script>

<style scoped>
ion-button span {
    text-transform: none;
    text-overflow: ellipsis;
    overflow-x: hidden;
    height: 1.1em;
    max-width: 80%;
}

ion-button {
    text-transform: none;
}

.skip-button {
    --padding-start: 5.5px;
    --padding-end: 5.5px;
    height: 36px;
    width: 36px;
}

.hidden {
    visibility: hidden;
}

input[type=file], .invisible-input {
  visibility: hidden;
  display: none;
}

#chartContainer, #videoContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
    flex: 1 1 0px;
}

#interaction-container {
    --padding-start: 16px;
}

.ios #interaction-container {
    --padding-start: 12px;
}

#interaction-container ion-label {
    overflow: visible;
}

#settings-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--ion-color-medium-light);
    padding-left: 10px;
    padding-right: 10px;
}

.ios #settings-buttons {
    padding-top: 3px;
}

#chartContainer > div, #videoContainer > div {
    display: flex;
    flex-direction: column;
    width: 50%;
}

#chartContainer .canvas-container {
    position: relative;
    flex-grow: 10;
    flex-shrink: 1;
    min-height: 0px;
}

#chartContainer > div:first-of-type, #videoContainer > div:first-of-type {
    border-right: 1px solid var(--ion-color-medium-light);
}

/* Relative container for absolute children to be independent within. Fills remaining space in between fully. */
.video-container {
  position: relative;
  /* Grow to maximum size but not smaller */
  flex-grow: 100;
  flex-shrink: 0;
  flex-basis: 0px;
  display: flex;
}

#visibility-toggle {
    visibility: hidden;
    display: none;
    align-items: center;
}

#visibility-toggle ion-icon {
    font-size: 1.5em;
    margin: 10px;
}

.optional-range {
    visibility: hidden;
    display: none;
}

@media (orientation: portrait) and (max-width: 700px) { 
    #visibility-toggle {
        visibility: visible;
        display: flex;
    }

    #chartContainer, #videoContainer {
        flex-direction: column;
        height: 100%;
    }

    #chartContainer > div, #videoContainer > div {
        width: 100%;
        height: 50%;
    }

    .hidden-part {
        visibility: hidden!important;
        display: none!important;
    }

    .optional-range {
        visibility: visible;
        display: flex;
    }

    #chartContainer > div:first-of-type, #videoContainer > div:first-of-type {
        border: none;
    }
}

@media (orientation: landscape) and (max-height: 700px) {
    #interaction-container {
        display: none;
        visibility: hidden;
    }

    #settings-buttons {
        border-top: none;
    }

    #visibility-toggle {
        visibility: visible;
        display: flex;
    }

    #chartContainer, #videoContainer {
        height: 100%;
    }

    .hidden-part {
        visibility: hidden!important;
        display: none!important;
    }

    .optional-range {
        visibility: visible;
        display: flex;
    }
}

ion-range {
    padding-top: 8px;
}

ion-range ion-button:first-of-type {
    margin-right: 25px;
}

ion-range ion-button:last-of-type {
    margin-left: 25px;
}

#both-range {
    padding-left: 0px;
    padding-right: 0px;
}

.ios #both-range::part(pin) {
    background: var(--ion-background-color, white);
}

/* Video is invisible. It is drawn inside canvas when needed. */
video {
  display: none;
}

.zoom-buttons {
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 12px;
}

.zoom-buttons ion-button {
    height: 36px;
    width: 36px;
    font-size: 1.5em;
    --padding-start: 6px;
    --padding-end: 6px;
    margin-left: 5px;
}

.play-button {
    position: absolute;
    left: 0px;
    bottom: 0px;
    margin: 12px;
    height: 36px;
    width: 36px;
    font-size: 1.5em;
    --padding-start: 6px;
    --padding-end: 6px;
    z-index: 90;
}

.graph-buttons {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 5px 12px;
}

.graph-buttons ion-fab-button {
    height: 36px;
    width: 36px;
    --padding-start: 0px;
    --padding-end: 0px;
    margin: 0px 2.5px;
    margin-inline: 2.5px;
    z-index: 90;
}

.graph-buttons ion-icon {
    font-size: 1.5em;
}

ion-fab.graph-buttons {
    height: 36px;
    width: 36px;
    font-size: 1.5em;
}

.graph-buttons ion-fab-list {
    min-width: 36px;
    min-height: 36px;
    height: 36px;
    margin: 0px 41px;
    margin-inline: 41px;
}

.disable-line {
    top: 8px;
    width: 1px;
    height: 20px;
    position: absolute;
    transform: rotate(-50deg);
    background: var(--ion-color-contrast);
    border: 1px solid var(--ion-color-contrast);
    border-radius: 10px;
}
</style>
